// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

//= require trix
import "trix";
import "@rails/actiontext";
import "../packs/header/header";
import 'select2/dist/css/select2.css';
import 'select2';

$('.navbar-toggler').on('click', function () {
  $('.navbar-collapse').toggle('show');
  $('nav').toggleClass('header-expanded', [125]);
});

// Turbolinks.BrowserAdapter.prototype.reload = function () {
//   window.scrollTo(0, 0)
//   window.location.reload()
// }

if ('scrollRestoration' in window.history) {
  window.history.scrollRestoration = 'manual';
}
